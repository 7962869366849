
import { computed, ref, onUnmounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';

import dateformat from 'dateformat';
import Toolbar from 'primevue/toolbar';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

import { Icon, Loader } from '../../../components/ui';
import Modal from './_photo.vue';
import Settings from './_settings.vue';
import { useStores } from '@/store/Stores';

export default {
  components: {
    Toolbar,
    Icon,
    Checkbox,
    Modal,
    Dialog,
    Button,
    Loader,
    Settings
  },
  setup() {
    const { carStore, carStoreState, headerStore } = useStores();

    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const confirm = useConfirm();
    const { t } = useI18n();

    carStore.init(route.params.id);
    headerStore.setTitleComponent('CarTitle');

    const data = computed(() => carStoreState.data);
    const isDsplaySettings = ref(false);
    const isSelectAll = ref(false);
    const selectOriginal = ref(false);
    const selectBanner = ref(false);
    const isShow = ref(false);
    const selectedIndex = ref(null);
    const isSelected = ref(false);

    const selectables = computed(() => carStoreState.data.angles.filter(({ photo_url }) => photo_url));
    const withProcessedPhoto = computed(() => carStore.withProcessedPhoto());

    function getCar() {
      console.log(withProcessedPhoto.value);
      setTimeout(() => {
        if (withProcessedPhoto.value && carStoreState.isTestRequesting) {
          carStore.init(route.params.id, true);
          getCar();
        }
      }, 2000);
    }

    function addExtraPhoto(event) {
      const file = event.target.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        const body = {
          car: route.params.id,
          photo_base: reader.result
        };

        carStore.uploadExtraPhoto(body, toast, t);
      };
      reader.readAsDataURL(file);
    }

    watch(withProcessedPhoto, (newValue, oldValue) => {
      if (newValue) {
        carStore.setIsTestRequesting(true);
        getCar();
      }
    });

    function openModal(index) {
      isShow.value = true;
      selectedIndex.value = index;
    }

    function toggleAll() {
      if (isSelectAll.value) {
        isSelected.value = true;
        selectables.value.forEach((element) => {
          element.isSelected = true;
        });
        return;
      }
      isSelected.value = false;
      selectables.value.forEach((element) => {
        element.isSelected = false;
      });
    }

    function checkIsSelectedAll() {
      isSelected.value = selectables.value.find(({ isSelected }) => isSelected);
      isSelectAll.value = selectables.value.length && selectables.value.find(({ isSelected }) => !isSelected) === undefined;
    }

    function download() {
      const photosIDS = selectables.value.filter(({ isSelected }) => isSelected).map(({ photo_id }) => photo_id);

      if (photosIDS && photosIDS.length) {
        const body = {
          car: route.params.id,
          photos: photosIDS,
          with_original: selectOriginal.value
        };

        carStore.downloadPhoto(body, toast, t);
      }
    }

    function deleteVehicle() {
      carStore.deleteCar(route.params.id, () => router.push({ name: 'Vehicles' }));
    }

    onUnmounted(() => {
      headerStore.setTitleComponent('');

      carStore.setIsTestRequesting(false);
      carStore.resetData();
    });

    function processing() {
      selectables.value
        .filter(({ isSelected }) => isSelected)
        .forEach((element) => {
          const body = { car: route.params.id, photo: element.photo_id };

          carStore.processPhoto(body, toast, t);
        });
    }

    const deleteVehiclePopup = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: t('confirm_delete'),
        accept: () => {
          deleteVehicle();
        },
        reject: () => {}
      });
    };

    return {
      state: carStoreState,
      dateformat,
      isSelectAll,
      selectOriginal,
      selectBanner,
      selectedIndex,
      isSelected,
      toggleAll,
      openModal,
      isShow,
      data,
      addExtraPhoto,
      checkIsSelectedAll,
      download,
      isDsplaySettings,
      processing,
      deleteVehiclePopup
    };
  }
};
