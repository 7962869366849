
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { dragscrollNext } from 'vue-dragscroll';
import Toolbar from 'primevue/toolbar';
import Button from 'primevue/button';
import 'vue-advanced-cropper/dist/style.css';

import { Icon, Loader } from '../../../components/ui';
import substrateImg from '../../../assets/img/substrate.png';
import { useStores } from '@/store/Stores';

export default {
  components: {
    Toolbar,
    Button,
    Icon,
    Loader
  },
  directives: {
    dragscroll: dragscrollNext
  },
  props: {
    total: Number,
    selected: Number,
    item: Object
  },
  emits: ['changeItem', 'uploadStarted'],
  setup(props, { emit }) {
    const { adminCarStoreState, adminCarStore, carStore, carStoreState } = useStores();

    const route = useRoute();
    const toast = useToast();
    const { t } = useI18n();
    const imagePreviev = ref();
    const base64Image = ref();
    const current = ref(props.selected);

    const data = computed(() => carStoreState.data);
    const photoUploading = computed(() => carStoreState.photoUploading);
    const currentItem = computed(() => props.item);

    function handlingArrowKey(e) {
      switch (e.key) {
        case 'ArrowLeft':
          e.preventDefault();
          callback(-1);
          break;
        case 'ArrowRight':
          e.preventDefault();
          callback(1);
          break;
      }
    }

    function callback(mod) {
      const summ = current.value + mod;
      if (summ >= 0 && summ < props.total) {
        current.value = summ;
        emit('changeItem', current.value);
      }
    }

    function uploadFile(event) {
      emit('uploadStarted', props.item);

      const file = event.target.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        currentItem.value.base_64_image = reader.result;
      };
      reader.readAsDataURL(file);
      const body = {
        car: route.params.id,
        photo: file,
        angle: props.item.id
      };
      adminCarStore.uploadPhoto(body, toast, t);
    }

    function uploadFileProcessed(event) {
      emit('uploadStarted', props.item);

      const file = event.target.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        currentItem.value.base_64_image = reader.result;
      };
      reader.readAsDataURL(file);
      const body = {
        car: route.params.id,
        photo: file,
        angle: props.item.id,
        carPhoto: currentItem.value.id
      };
      adminCarStore.uploadPhotoProcessed(body, toast, t);
    }
    function processing() {
      const body = { car: route.params.id, photo: props.item.photo_id };
      adminCarStore.processPhoto(body, toast, t);
    }

    function handlingImage({ canvas }) {
      base64Image.value = canvas.toDataURL();
    }

    onMounted(() => {
      document.addEventListener('keydown', handlingArrowKey, true);
    });
    onUnmounted(() => {
      document.removeEventListener('keypress', handlingArrowKey, true);
    });

    return {
      current,
      callback,
      data,
      handlingImage,
      processing,
      imagePreviev,
      uploadFile,
      uploadFileProcessed,
      base64Image,
      currentItem,
      photoUploading,
      substrateImg
    };
  }
};
