import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "p-field _base-search-size" }
const _hoisted_2 = { class: "p-input-icon-right" }
const _hoisted_3 = { class: "grid _relative" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_Empty = _resolveComponent("Empty")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_NoImg = _resolveComponent("NoImg")!
  const _component_Paginator = _resolveComponent("Paginator")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Toolbar, null, {
      left: _withCtx(() => [
        _createElementVNode("label", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_InputText, {
              type: "search",
              modelValue: $setup.search,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search) = $event)),
              placeholder: _ctx.$t('vehicles_page.search')
            }, null, 8, ["modelValue", "placeholder"]),
            _createVNode(_component_Icon, {
              name: "zoom",
              className: "p-field-icon"
            })
          ])
        ])
      ]),
      right: _withCtx(() => [
        _createVNode(_component_router_link, {
          to: { name: 'CreatingVehicle' },
          class: "p-button mb-4 mt-5"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Icon, {
              name: "plus",
              className: ""
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('vehicles_page.add_vehicle')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (!$setup.state.isLoading && (!$setup.state.data || !$setup.state.data.length))
      ? (_openBlock(), _createBlock(_component_Empty, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      ($setup.state.isLoading)
        ? (_openBlock(), _createBlock(_component_Loader, {
            key: 0,
            isOverlay: $setup.state.data && $setup.state.data.length
          }, null, 8, ["isOverlay"]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.state.data, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.id,
          class: "col-12 md:col-6 lg:col-4"
        }, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(_ctx.$style.card),
            to: { name: 'Vehicle', params: { id: item.id } }
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass([_ctx.$style.imgBox, "flex align-items-center justify-content-center"])
              }, [
                (item.photo)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: `${item.photo.url}`,
                      alt: "",
                      class: _normalizeClass([_ctx.$style.img, "_img-base"])
                    }, null, 10, _hoisted_4))
                  : (_openBlock(), _createBlock(_component_NoImg, { key: 1 }))
              ], 2),
              (item.vehicle_name)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style.name)
                  }, _toDisplayString(item.vehicle_name), 3))
                : (_openBlock(), _createElementBlock("p", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style.name)
                  }, _toDisplayString(item.brand) + " " + _toDisplayString(item.model), 3)),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.$style.vin)
              }, _toDisplayString(item.vin), 3),
              (!item.moderated && $setup.state.carsNeedPremoderation)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 2,
                    class: _normalizeClass(_ctx.$style.unmoderated)
                  }, _toDisplayString(_ctx.$t('vehicles_page.on_moderation')), 3))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass([_ctx.$style.footer, "flex align-items-center justify-content-between"])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass([[_ctx.$style.slots, item.photos_count === item.angles_count && _ctx.$style.full], "_label py-1 px-3"])
                }, _toDisplayString(item.photos_count || 0) + "/" + _toDisplayString(item.angles_count), 3),
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.$style.date)
                }, _toDisplayString($setup.dateformat(item.created_at * 1000, 'dd.mm.yyyy')), 3)
              ], 2)
            ]),
            _: 2
          }, 1032, ["class", "to"])
        ]))
      }), 128))
    ]),
    _createVNode(_component_Paginator, {
      currentPage: $setup.state.meta.current_page || _ctx.$route.query.page || 1,
      total: $setup.state.meta.total,
      lastPage: $setup.state.meta.last_page,
      perPage: $setup.state.meta.perPage,
      count: $setup.state.meta.to
    }, null, 8, ["currentPage", "total", "lastPage", "perPage", "count"])
  ], 64))
}