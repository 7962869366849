
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

import API from '../../../api';
import { useStores } from '@/store/Stores';
export default {
  components: {
    InputText,
    Button
  },
  setup() {
    const { accountStoreState } = useStores();

    const toast = useToast();
    const { t } = useI18n();
    const router = useRouter();
    const submiting = ref(false);

    const model = ref({
      vin: ''
    } as any);
    const rules = {
      vin: { required }
    };
    const v$ = useVuelidate(rules, model);
    const handleSubmit = async (isFormValid, isGotoFormVehicle) => {
      v$.value.$touch();
      if (!isFormValid) {
        return;
      }
      submiting.value = true;
      model.value.vehicle_name = `${model.value.brand} ${model.value.model}`;
      model.value = {
        background_id: accountStoreState.data.settings.background_id,
        background_type: accountStoreState.data.settings.background_type,
        logo_id: accountStoreState.data.settings.logo_id,
        logo_position: accountStoreState.data.settings.logo_position,
        licence_plate_id: accountStoreState.data.settings.licence_plate_id,
        preset_id: accountStoreState.data.settings.preset_id,
        cutting: accountStoreState.data.settings.cutting,
        plate_blur: accountStoreState.data.settings.plate_blur,
        plate_image: accountStoreState.data.settings.plate_image,
        polishing: accountStoreState.data.settings.polishing,
        rim_polishing: accountStoreState.data.settings.rim_polishing,
        reflective_floor: accountStoreState.data.settings.reflective_floor,
        ...model.value
      };
      delete model.value.id;
      delete model.value.user_id;
      try {
        await API.post('cars', model.value);
        //toast.add({ severity: 'success', summary: t('success'), detail: t('toast.vehicle_added'), life: 3000 })
        if (isGotoFormVehicle) {
          window.location.href = `/vehicles/${model.value.id}`;
        } else {
          v$.value.$reset();
          submiting.value = false;
          model.value = {};
        }
      } catch (error) {
        let keys: string[] = Object.keys(error.response.data.errors);
        let errorMessage: string = '';

        for (let key of keys) {
          if (errorMessage != '') {
            errorMessage += ' ';
          }

          errorMessage += error.response.data.errors[key];
        }

        submiting.value = false;
        toast.add({ severity: 'error', summary: t('error'), detail: errorMessage, life: 3000 });
      }
    };
    return {
      v$,
      model,
      submiting,
      handleSubmit
    };
  }
};
