
import { computed, ref, onUnmounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';

import dateformat from 'dateformat';
import Toolbar from 'primevue/toolbar';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

import { Icon, Loader } from '../../../components/ui';
import Modal from './_photo.vue';
import Settings from './_settings.vue';
import { useStores } from '@/store/Stores';

export default {
  components: {
    Toolbar,
    Icon,
    Checkbox,
    Modal,
    Dialog,
    Button,
    Loader,
    Settings
  },
  setup() {
    const { adminCarStoreState, adminCarStore, headerStore } = useStores();

    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const confirm = useConfirm();
    const { t } = useI18n();

    adminCarStore.init(route.params.id);

    headerStore.setTitle('');
    headerStore.setTabs([]);
    headerStore.setTitleComponent('CarTitleAdmin');

    const state = computed(() => adminCarStoreState);
    const data = computed(() => adminCarStoreState.data);
    const isDsplaySettings = ref(false);
    const isSelectAll = ref(false);
    const selectOriginal = ref(false);
    const selectBanner = ref(false);
    const isShow = ref(false);
    const selectedIndex = ref(null);
    const isSelected = ref(false);

    const selectables = computed(() => adminCarStoreState.data.angles.filter(({ photo_url }) => photo_url));
    const withProcessedPhoto = computed(() => adminCarStore.withProcessedPhoto());

    function getCar() {
      console.log(withProcessedPhoto.value);
      setTimeout(() => {
        if (withProcessedPhoto.value && adminCarStoreState.isTestRequesting) {
          if (!isDsplaySettings.value) {
            adminCarStore.init(route.params.id, true);
          }
          getCar();
        }
      }, 2000);
    }

    watch(withProcessedPhoto, (newValue, oldValue) => {
      if (newValue) {
        adminCarStore.setIsTestRequesting(true);
        getCar();
      }
    });

    function openModal(index) {
      isShow.value = true;
      selectedIndex.value = index;
    }

    function uploadStarted(data: any) {
      adminCarStore.dispatch(async (state) => {
        const angle = state.data.angles.find(({ id }) => id === data.id);

        if (angle) {
          angle.defect_state = 'ok';
        }
      });
    }

    function toggleAll() {
      if (isSelectAll.value) {
        isSelected.value = true;
        selectables.value.forEach((element) => {
          element.isSelected = true;
        });
        return;
      }
      isSelected.value = false;
      selectables.value.forEach((element) => {
        element.isSelected = false;
      });
    }

    function toggleShowToFix() {
      adminCarStore.dispatch(async (state) => {
        state.data.showToFix = !state.data.showToFix;
      });
    }

    function checkIsSelectedAll() {
      isSelected.value = selectables.value.find(({ isSelected }) => isSelected);
      isSelectAll.value = selectables.value.length && selectables.value.find(({ isSelected }) => !isSelected) === undefined;
    }

    function download() {
      const photosIDS = selectables.value.filter(({ isSelected }) => isSelected).map(({ photo_id }) => photo_id);

      if (photosIDS && photosIDS.length) {
        const body = { car: route.params.id, photos: photosIDS, with_original: selectOriginal.value };
        adminCarStore.downloadPhoto(body, toast, t);
      }
    }

    function approveVehicle() {
      adminCarStore.approve(route.params.id, () => {
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.vehicle_approved'), life: 3000 });

        adminCarStore.init(route.params.id, true);
      });
    }

    onUnmounted(() => {
      headerStore.setTitleComponent('');

      adminCarStore.setIsTestRequesting(false);
      adminCarStore.resetData();
    });

    function processing() {
      selectables.value
        .filter(({ isSelected }) => isSelected)
        .forEach((element) => {
          const body = { car: route.params.id, photo: element.photo_id };
          adminCarStore.processPhoto(body, toast, t);
        });
    }

    const approveVehiclePopup = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: t('confirm_approve'),
        accept: () => {
          approveVehicle();
        },
        reject: () => {}
      });
    };

    return {
      adminCarStoreState,

      state,
      dateformat,
      isSelectAll,
      selectOriginal,
      selectBanner,
      selectedIndex,
      isSelected,
      toggleAll,
      openModal,
      isShow,
      data,
      checkIsSelectedAll,
      download,
      uploadStarted,
      isDsplaySettings,
      processing,
      toggleShowToFix,
      approveVehiclePopup,
      approveVehicle
    };
  }
};
