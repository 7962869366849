import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "_heading-2" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.modal)
  }, [
    _createVNode(_component_Toolbar, { class: "p-4" }, {
      left: _withCtx(() => [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.current + 1) + " / " + _toDisplayString(_ctx.currentItem.isExtra ? 'Extra' : _ctx.currentItem.comment), 1)
      ]),
      right: _withCtx(() => [
        _createVNode(_component_Button, {
          onClick: _cache[0] || (_cache[0] = () => _ctx.callback(-1)),
          class: "p-button-outlined p-button-gray p-button-rounded mr-3 p-button-only-icon"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Icon, { name: "arrow_left" })
          ]),
          _: 1
        }),
        _createVNode(_component_Button, {
          onClick: _cache[1] || (_cache[1] = () => _ctx.callback(1)),
          class: "p-button-outlined p-button-gray p-button-rounded mr-5 p-button-only-icon"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Icon, { name: "arrow_right" })
          ]),
          _: 1
        }),
        _createVNode(_component_Button, {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('closeModal'))),
          class: "p-button-outlined p-button-gray p-button-rounded p-button-only-icon"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Icon, { name: "close" })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.content)
    }, [
      _createElementVNode("img", {
        src: _ctx.substrateImg,
        alt: "",
        class: _normalizeClass(_ctx.$style.substrate)
      }, null, 10, _hoisted_2),
      _createElementVNode("img", {
        alt: "",
        src: _ctx.currentItem.base_64_image || _ctx.currentItem.processed_url || _ctx.currentItem.thumbnail || _ctx.currentItem.loaded_photo || _ctx.currentItem.car,
        class: _normalizeClass([
          _ctx.$style.car,
          !(_ctx.currentItem.base_64_image || _ctx.currentItem.processed_url || _ctx.currentItem.thumbnail || _ctx.currentItem.loaded_photo) && _ctx.$style.carReference
        ])
      }, null, 10, _hoisted_3),
      (_ctx.item.status == 1 || _ctx.item.status == 3)
        ? (_openBlock(), _createBlock(_component_Loader, {
            key: 0,
            isOverlay: true
          }))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_component_Toolbar, { class: "p-4" }, {
      left: _withCtx(() => [
        (!_ctx.currentItem.isExtra)
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              class: _normalizeClass([[_ctx.$style.action, _ctx.item.status == 1 || _ctx.item.status == 3 || (_ctx.photoUploading[_ctx.currentItem.id] && 'disabled')], "p-button"])
            }, [
              _createElementVNode("input", {
                type: "file",
                name: "",
                id: "",
                disabled: _ctx.item.status == 1 || _ctx.item.status == 3 || _ctx.photoUploading[_ctx.currentItem.id],
                class: "p-upload-input",
                onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.uploadFile && _ctx.uploadFile(...args)))
              }, null, 40, _hoisted_4),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t(_ctx.currentItem.loaded_photo ? 'vehicles_page.replacePhoto' : 'vehicles_page.upload_photo')), 1)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      right: _withCtx(() => [
        (!_ctx.currentItem.isExtra)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              class: _normalizeClass([_ctx.$style.action, "p-button-outlined p-button-gray ml-4"]),
              onClick: _ctx.processing,
              disabled: _ctx.item.status == 1 || !_ctx.currentItem.loaded_photo
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Icon, { name: "upload-cloud" }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('vehicles_page.processing')), 1)
              ]),
              _: 1
            }, 8, ["class", "onClick", "disabled"]))
          : _createCommentVNode("", true),
        (_ctx.currentItem.isExtra)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              class: _normalizeClass([_ctx.$style.action, "p-button-outlined p-button-gray ml-4"]),
              onClick: _ctx.removeExtraPhoto
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('vehicles_page.remove_extra_photo')), 1)
              ]),
              _: 1
            }, 8, ["class", "onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}