
import { ref, computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import Tooltip from 'primevue/tooltip';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Toolbar from 'primevue/toolbar';

import { Icon, Empty } from '../../../components/ui';
import referenceCutting from '../../../assets/img/reference_cutting.jpg';
import referenceBlur from '../../../assets/img/reference_cutting.jpg';
import referencePlate from '../../../assets/img/reference_cutting.jpg';
import { useStores } from '@/store/Stores';
import { RoomComponent, RoomComponentsStore } from '@/store/modules/RoomComponentsStore';

import VehicleLicensePlatesDialog from './VehicleLicensePlatesDialog/VehicleLicensePlatesDialog.vue';

export default defineComponent({
  components: {
    Icon,
    Dropdown,
    InputSwitch,
    Button,
    Dialog,
    Toolbar,
    Empty,
    VehicleLicensePlatesDialog
  },
  directives: {
    'tooltip': Tooltip
  },
  data() {
    return {
      isPlateSelectorShown: false
    };
  },
  methods: {
    handlePlateSelected(plate: any) {
      this.carStore.dispatch(async (state) => {
        state.data.settings.licence_plate_id = plate.id;
        state.data.settings.licence_plate = plate;
      });
      this.isPlateSelectorShown = false;
    }
  },
  setup() {
    const { carStore, carStoreState, settingsStore, settingsStoreState, roomComponentsStore, roomComponentsStoreState } = useStores();

    const isShow = ref();
    const helper = ref();
    const { t } = useI18n();
    const toast = useToast();
    const imagesListKey = ref();

    roomComponentsStore.init();

    settingsStore.init('bg', 'companyBackgrounds');
    settingsStore.init('logos', 'companyLogos');
    settingsStore.init('banners', 'companyBanners');

    const settings = computed(() => carStoreState.data.settings);

    const previewBG = computed(() => settingsStoreState.bg.data.find(({ id }) => id === settings.value.background_id) || {});
    const previewLogo = computed(() => settingsStoreState.logos.data.find(({ id }) => id === settings.value.logo_id) || {});
    const previewBanenr = computed(() => settingsStoreState.banners.data.find(({ id }) => id === settings.value.banner_id) || {});
    const previewRoom = computed(() => roomComponentsStoreState.rooms.find(({ id }) => id === settings.value.room_id) || ({} as RoomComponent));

    const helperImage = {
      cutting: referenceCutting,
      blur: referenceBlur,
      plate: referencePlate
    };

    const optionsCrop = [
      { value: 'Full', label: 'Full crop' },
      { value: 'Half', label: 'Half crop' },
      { value: 'Full with blur', label: 'Blur crop' },
      { value: 'Half with blur', label: 'Half blur crop' }
    ];

    const optionsBG = computed(() => [
      { value: 'Base', label: t('settings_page.base') },
      { value: 'Photo Box', label: t('settings_page.photo_box') }
    ]);

    const optionsLogoPosition = [
      { value: 'Top Left', label: 'Top left' },
      { value: 'Bottom Left', label: 'Bottom left' },
      { value: 'Top Right', label: 'Top right' },
      { value: 'Bottom Right', label: 'Bottom right' }
    ];

    function setImage(id) {
      isShow.value = false;
      switch (imagesListKey.value) {
        case 'logos':
          settings.value.logo_id = id;
          break;
        case 'banners':
          settings.value.banner_id = id;
          break;
        default:
          settings.value.background_id = id;
          if (previewBG.value.company_id) {
            settings.value.background_type = 'Base';
          }
          break;
      }
    }
    function removeImage(type) {
      switch (type) {
        case 'logos':
          settings.value.logo_id = null;
          break;
        case 'banners':
          settings.value.banner_id = null;
          break;
      }
    }

    const setRoom = (room: RoomComponent) => {
      settings.value.room_id = room.id;
      isShow.value = false;
    };

    function openModal(content) {
      isShow.value = true;
      imagesListKey.value = content;
    }

    function submit() {
      const body = carStoreState.data;
      body.car = (carStoreState.data as any).id;

      carStore.saveChanges(body, toast, t);
    }

    function handlingRelatedSwitches(param) {
      switch (param) {
        case 'blur':
          settings.value.plate_image = false;
          break;
        case 'image':
          settings.value.plate_blur = false;
          break;
        default:
          break;
      }
    }

    return {
      carStore,
      carStoreState,
      settingsStoreState,
      isShow,
      optionsCrop,
      imagesListKey,
      optionsBG,
      previewBG,
      optionsLogoPosition,
      previewLogo,
      previewBanenr,
      setRoom,
      previewRoom,
      settings,
      submit,
      openModal,
      setImage,
      handlingRelatedSwitches,
      helper,
      helperImage,
      removeImage,
      roomComponentsStore,
      roomComponentsStoreState
    };
  }
});
