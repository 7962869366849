
import { defineComponent } from 'vue';

import InputSwitch from 'primevue/inputswitch';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Toolbar from 'primevue/toolbar';
import { useStores } from '@/store/Stores';

import { Icon } from '../../../../components/ui';

export default defineComponent({
  components: {
    Icon,
    InputSwitch,
    Button,
    Dialog,
    Toolbar
  },
  props: {
    isShown: Boolean
  },
  emits: ['close', 'onRoomSet'],
  setup() {
    const { adminCarStoreState, adminCarStore } = useStores();

    return {
      adminCarStoreState,
      adminCarStore
    };
  }
});
