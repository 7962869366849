
import { computed, defineComponent } from 'vue';

import InputSwitch from 'primevue/inputswitch';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Toolbar from 'primevue/toolbar';
import { useStores } from '@/store/Stores';

import { Icon } from '../../../../components/ui';
import API from '../../../../api';

export default defineComponent({
  components: {
    Icon,
    InputSwitch,
    Button,
    Dialog,
    Toolbar
  },
  props: {
    isShown: Boolean
  },
  emits: ['close', 'onPlateSet'],
  methods: {
    async uploadFile(e: Event) {
      const file = (e.target as any).files[0];

      if (file) {
        const body = {
          photo: file
        };

        const plate: any = (
          (await API.post(
            'plate',
            {
              photo: file
            },
            'formData'
          )) as any
        ).data;

        this.carStore.dispatch(async (state) => {
          state.data.settings.licence_plates = [plate, ...state.data.settings.licence_plates];
          state.data.settings.licence_plate = plate;
          state.data.settings.licence_plate_id = plate.id;
        });

        this.$emit('close');
      }
    }
  },
  setup() {
    const { carStoreState, carStore } = useStores();

    return {
      carStoreState,
      carStore,

      licence_plates: computed(() => (carStoreState.data.settings.licence_plates ? carStoreState.data.settings.licence_plates : []))
    };
  }
});
