import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid mb-3 align-items-center" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "grid mb-3 align-items-center" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "grid flex align-items-center mb-3" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "col flex align-items-center" }
const _hoisted_8 = { class: "grid flex align-items-center mb-3" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "col flex align-items-center" }
const _hoisted_11 = { class: "grid flex align-items-center mb-3" }
const _hoisted_12 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Button = _resolveComponent("Button")!
  const _component_VehicleModerationLicensePlatesDialog = _resolveComponent("VehicleModerationLicensePlatesDialog")!
  const _component_VehicleModerationScenesDialog = _resolveComponent("VehicleModerationScenesDialog")!
  const _component_VehicleModerationHelperDialog = _resolveComponent("VehicleModerationHelperDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.wrap, "grid justify-content-between"])
    }, [
      _createElementVNode("form", {
        class: _normalizeClass([_ctx.$style.form, "_relative col mt-4"]),
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('settings_page.default_scene')), 1),
          _createElementVNode("div", {
            class: _normalizeClass(["col", _ctx.$style.settingValue])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.bgBox),
              style: _normalizeStyle(`background-image: url(${_ctx.previewRoom.preview});`)
            }, [
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = () => (_ctx.isSceneSelectorShown = true)),
                type: "button",
                class: _normalizeClass(_ctx.$style.bgAction)
              }, [
                _createVNode(_component_Icon, {
                  name: "edit",
                  className: _ctx.$style.bgBoxIcon
                }, null, 8, ["className"])
              ], 2)
            ], 6)
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('settings_page.background_type')), 1),
          _createElementVNode("div", {
            class: _normalizeClass(["col", _ctx.$style.settingValue])
          }, [
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.settings.background_type,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.settings.background_type) = $event)),
              options: _ctx.optionsBG,
              optionLabel: "label",
              optionValue: "value",
              class: "_full-width"
            }, null, 8, ["modelValue", "options"])
          ], 2)
        ])
      ], 34),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.switches, "col mt-4"])
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('settings_page.cutting')), 1),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              class: "inline-flex align-items-center mr-3",
              onClick: _cache[3] || (_cache[3] = () => (_ctx.helperDialogType = 'cutting'))
            }, [
              _createVNode(_component_Icon, {
                name: "info",
                className: _ctx.$style.info
              }, null, 8, ["className"])
            ]),
            _createVNode(_component_InputSwitch, {
              modelValue: _ctx.settings.cutting,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.settings.cutting) = $event))
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('settings_page.licence_plate_blur')), 1),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", {
              class: "inline-flex align-items-center mr-3",
              onClick: _cache[5] || (_cache[5] = () => (_ctx.helperDialogType = 'blur'))
            }, [
              _createVNode(_component_Icon, {
                name: "info",
                className: _ctx.$style.info
              }, null, 8, ["className"])
            ]),
            _createVNode(_component_InputSwitch, {
              modelValue: _ctx.settings.plate_blur,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.settings.plate_blur) = $event)),
              onChange: _cache[7] || (_cache[7] = () => _ctx.handlingRelatedSwitches('blur'))
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('settings_page.licence_plate_image')), 1),
          _createElementVNode("div", {
            class: _normalizeClass(["col", _ctx.$style.settingValue])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.bgBox),
              style: _normalizeStyle({
              backgroundImage: _ctx.settings.licence_plate ? `url(${_ctx.settings.licence_plate.url})` : 'none'
            })
            }, [
              _createElementVNode("button", {
                onClick: _cache[8] || (_cache[8] = () => (_ctx.isPlateSelectorShown = true)),
                type: "button",
                class: _normalizeClass(_ctx.$style.bgAction)
              }, [
                _createVNode(_component_Icon, {
                  name: "edit",
                  className: _ctx.$style.bgBoxIcon
                }, null, 8, ["className"])
              ], 2)
            ], 6)
          ], 2)
        ])
      ], 2)
    ], 2),
    _createVNode(_component_Button, {
      class: _normalizeClass([_ctx.$style.action, "p-button-big _full-width mt-5"]),
      onClick: _ctx.submit
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('save_change')), 1)
      ]),
      _: 1
    }, 8, ["class", "onClick"]),
    _createVNode(_component_VehicleModerationLicensePlatesDialog, {
      isShown: _ctx.isPlateSelectorShown,
      onClose: _cache[9] || (_cache[9] = () => (_ctx.isPlateSelectorShown = false)),
      onOnPlateSet: _ctx.handlePlateSelected
    }, null, 8, ["isShown", "onOnPlateSet"]),
    _createVNode(_component_VehicleModerationScenesDialog, {
      isShown: _ctx.isSceneSelectorShown,
      onClose: _cache[10] || (_cache[10] = () => (_ctx.isSceneSelectorShown = false)),
      onOnRoomSet: _ctx.handleSceneSelected
    }, null, 8, ["isShown", "onOnRoomSet"]),
    _createVNode(_component_VehicleModerationHelperDialog, {
      helperType: _ctx.helperDialogType,
      onClose: _cache[11] || (_cache[11] = () => (_ctx.helperDialogType = null))
    }, null, 8, ["helperType"])
  ], 64))
}