
import { defineComponent } from 'vue';

import InputSwitch from 'primevue/inputswitch';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Toolbar from 'primevue/toolbar';

import { Icon } from '../../../../components/ui';

import referenceCutting from '../../../../assets/img/reference_cutting.jpg';
import referenceBlur from '../../../../assets/img/reference_cutting.jpg';
import referencePlate from '../../../../assets/img/reference_cutting.jpg';

export default defineComponent({
  components: {
    Icon,
    InputSwitch,
    Button,
    Dialog,
    Toolbar
  },
  props: {
    helperType: String
  },
  emits: ['close'],
  data() {
    return {
      helperImage: {
        cutting: referenceCutting,
        blur: referenceBlur,
        plate: referencePlate
      }
    };
  }
});
