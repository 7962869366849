
import { defineComponent } from 'vue';
import dateformat from 'dateformat';
import Toolbar from 'primevue/toolbar';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';

import { Icon, NoImg, Loader, Empty } from '../../components/ui';
import { Paginator } from '../../components/common';
import { Stores, useStores } from '@/store/Stores';
import { companies } from '@/store/modules';

export default defineComponent({
  data() {
    return {
      search: ''
    };
  },
  watch: {
    'search'(newValue: string) {
      this.$router.push({ name: this.$route.name as any, query: { search: newValue } });
    },
    '$route'() {
      this.refresh();
    }
  },
  methods: {
    refresh() {
      this.store.init({ per_page: 20, page: this.$route.query.page || 1, search: this.search });
    }
  },
  created() {
    this.refresh();
  },
  setup() {
    const { adminCarsStore, adminCarsStoreState } = useStores();

    return {
      store: adminCarsStore,
      state: adminCarsStoreState,

      dateformat
    };
  },
  components: {
    Toolbar,
    InputText,
    Dropdown,
    Icon,
    NoImg,
    Paginator,
    Loader,
    Empty
  },
  computed: {
    premoderationFilterOptions() {
      return [
        { value: 'all', label: this.$t('vehicles_page.all') },
        { value: 'premoderation', label: this.$t('vehicles_page.for_premoderation') },
        { value: 'to_fix', label: this.$t('vehicles_page.to_fix') }
      ];
    },
    companies() {
      const companiesList = this.state.companies ? this.state.companies.map((item: any) => ({ id: item.id, title: item.title })) : [];

      return [
        {
          id: -1,
          title: this.$t('vehicles_page.all_companies')
        },

        ...companiesList
      ];
    }
  }
});
