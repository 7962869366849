
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import dateformat from 'dateformat';
import Toolbar from 'primevue/toolbar';
import InputText from 'primevue/inputtext';

import { Icon, NoImg, Loader, Empty } from '../../components/ui';
import { Paginator } from '../../components/common';
import { useStores } from '@/store/Stores';

export default {
  components: {
    Toolbar,
    InputText,
    Icon,
    NoImg,
    Paginator,
    Loader,
    Empty
  },
  setup() {
    const { carsStore, carsStoreState } = useStores();

    const router = useRouter();
    const route = useRoute();
    const search = ref();

    carsStore.init({ per_page: 9, page: route.query.page || 1, search: search.value });

    watch(route, () => {
      carsStore.init({ per_page: 9, page: route.query.page || 1, search: search.value });
    });

    watch(search, (newValue) => {
      router.push({ name: route.name as any, query: { search: newValue } });
    });

    return {
      state: carsStoreState,
      dateformat,
      search
    };
  }
};
