
import { ref, computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import Tooltip from 'primevue/tooltip';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Toolbar from 'primevue/toolbar';

import { Icon, Empty } from '../../../components/ui';
import { useRoute } from 'vue-router';
import { useStores } from '@/store/Stores';
import { RoomComponent } from '@/store/modules/RoomComponentsStore';

import VehicleModerationLicensePlatesDialog from './VehicleModerationLicensePlatesDialog/VehicleModerationLicensePlatesDialog.vue';
import VehicleModerationHelperDialog from './VehicleModerationHelperDialog/VehicleModerationHelperDialog.vue';
import VehicleModerationScenesDialog from './VehicleModerationScenesDialog/VehicleModerationScenesDialog.vue';

export default defineComponent({
  data() {
    return {
      isPlateSelectorShown: false,
      isSceneSelectorShown: false,
      helperDialogType: null as string
    };
  },
  components: {
    Icon,
    Dropdown,
    InputSwitch,
    Button,
    Dialog,
    Toolbar,
    Empty,
    VehicleModerationLicensePlatesDialog,
    VehicleModerationHelperDialog,
    VehicleModerationScenesDialog
  },
  directives: {
    'tooltip': Tooltip
  },
  methods: {
    handleSceneSelected(room: RoomComponent) {
      this.adminCarStore.dispatch(async (state) => {
        state.data.settings.room_id = room.id;
      });
      this.isSceneSelectorShown = false;
    },
    handlePlateSelected(plate: any) {
      this.adminCarStore.dispatch(async (state) => {
        state.data.settings.licence_plate_id = plate.id;
        state.data.settings.licence_plate = plate;
      });
      this.isPlateSelectorShown = false;
    }
  },
  setup() {
    const { adminCarStoreState, adminCarStore } = useStores();

    const route = useRoute();
    const { t } = useI18n();
    const toast = useToast();
    const imagesListKey = ref();

    adminCarStore.init(route.params.id, false);

    const settings = computed(() => adminCarStoreState.data.settings || ({} as any));

    const previewBG = computed(
      () => adminCarStoreState.data.settings.backgrounds.find(({ id }) => id === settings.value.background_id) || ({} as any)
    );
    const previewLogo = computed(() => adminCarStoreState.data.settings.logos.find(({ id }) => id === settings.value.logo_id) || ({} as any));
    const previewBanenr = computed(() => adminCarStoreState.data.settings.banners.find(({ id }) => id === settings.value.banner_id) || ({} as any));
    const previewRoom = computed(() => {
      if (!adminCarStoreState.data.settings.rooms) {
        return {} as RoomComponent;
      }
      const room = adminCarStoreState.data.settings.rooms.find(({ id }) => settings.value && id === settings.value.room_id);

      if (room) {
        return room;
      } else {
        return {} as RoomComponent;
      }
    });

    const optionsCrop = [
      { value: 'Full', label: 'Full crop' },
      { value: 'Half', label: 'Half crop' },
      { value: 'Full with blur', label: 'Blur crop' },
      { value: 'Half with blur', label: 'Half blur crop' }
    ];

    const optionsBG = computed(() => [
      { value: 'Base', label: t('settings_page.base') },
      { value: 'Photo Box', label: t('settings_page.photo_box') }
    ]);

    const optionsLogoPosition = [
      { value: 'Top Left', label: 'Top left' },
      { value: 'Bottom Left', label: 'Bottom left' },
      { value: 'Top Right', label: 'Top right' },
      { value: 'Bottom Right', label: 'Bottom right' }
    ];

    function removeImage(type) {
      switch (type) {
        case 'logos':
          settings.value.logo_id = null;
          break;
        case 'banners':
          settings.value.banner_id = null;
          break;
      }
    }

    function submit() {
      const body = adminCarStoreState.data;
      body.car = (adminCarStoreState.data as any).id;

      adminCarStore.saveChanges(body, toast, t);
    }

    function handlingRelatedSwitches(param) {
      switch (param) {
        case 'blur':
          settings.value.plate_image = false;
          break;
        case 'image':
          settings.value.plate_blur = false;
          break;
        default:
          break;
      }
    }

    return {
      adminCarStore,
      adminCarStoreState,

      optionsCrop,
      imagesListKey,
      optionsBG,
      previewRoom,
      optionsLogoPosition,
      previewLogo,
      previewBanenr,
      settings,
      submit,
      handlingRelatedSwitches,
      removeImage
    };
  }
});
